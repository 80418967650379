import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Form,
  Row,
  Col,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import axios from "axios";
import "./Session.css";
import TimezoneSelect from "react-timezone-select";
import moment from "moment";
import "moment-timezone";

const timezones = {
  "America/Chicago": "Central Time",
  "America/Los_Angeles": "Pacific Time",
  "America/Boise": "Mountain Time",
  "America/New_York": "Eastern Time",
  "Asia/Kolkata": "Chennai, Kolkata, Mumbai, New Delhi",
};

function replaceBetween(origin, startIndex, endIndex, insertion) {
  return (
    origin.substring(0, startIndex) + insertion + origin.substring(endIndex)
  );
}

function Session() {
  const [slots, setSlots] = useState([]);
  const [IsEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [newSession, setNewSession] = useState({
    slotId: "",
    startTime: "",
    endTime: "",
    description: "",
    eventStatus: "",
    recurringEventId: "",
    conferenceId: "",
    videoSavedLocation: "",
  });
  const [startTime, setStartTime] = useState({ date: "", time: "" });
  const [endTime, setEndTime] = useState({ date: "", time: "" });
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [sessions, setSessions] = useState([]);

  const setTimevalues = () => {
    const startTimedate = JSON.parse(
      JSON.stringify(
        moment
          .tz(
            newSession.startTime,
            selectedTimezone.value ? selectedTimezone.value : selectedTimezone
          )
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      )
    );

    const endTimedate = JSON.parse(
      JSON.stringify(
        moment
          .tz(
            newSession.endTime,
            selectedTimezone.value ? selectedTimezone.value : selectedTimezone
          )
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
      )
    );

    setStartTime({
      time: startTimedate.slice(11, 16),
      date: startTimedate.split("T")[0],
    });

    setEndTime({
      time: endTimedate.slice(11, 16),
      date: endTimedate.split("T")[0],
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getSlots();
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimevalues();
  }, [selectedTimezone]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimevalues();
  }, [newSession.startTime, newSession.endTime]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSlots = async () => {
    await axios
      .get(process.env.REACT_APP_API_ENDPOINT + "/slots/")
      .then((res) => {
        setSlots(res.data);
      });
  };

  const getSessions = async (reqId) => {
    await axios
      .get(process.env.REACT_APP_API_ENDPOINT + "/sessions/", {
        params: {
          id: reqId,
        },
      })
      .then((res) => {
        setSessions(res.data);
      })
      .catch((err) => {
        console.log("ERROR In getting Sessions for a slot");
        console.log(err);
      });
  };

  const setNewSessionValues = (reqId) => {
    for (let i in sessions) {
      if (sessions[i].id === reqId) {
        setNewSession({
          ...newSession,
          startTime: sessions[i].startTime,
          endTime: sessions[i].endTime,
          description: sessions[i].description,
          eventStatus: sessions[i].eventStatus,
          recurringEventId: sessions[i].recurringEventId,
          conferenceId: sessions[i].conferenceId,
          videoSavedLocation: sessions[i].videoSavedLocation,
        });
      }
    }
  };

  const setTimeEdit = (DateTime) => {
    let datetime = moment.tz(
      DateTime.date,
      selectedTimezone.value ? selectedTimezone.value : selectedTimezone
    );
    let time = DateTime.time;
    let hr = time.slice(0, 2);
    let mnt = time.slice(3, 5);
    datetime.set({ hour: hr, minute: mnt });
    return datetime.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  };

  const editSession = async (id) => {
    const start = setTimeEdit(startTime);
    const end = setTimeEdit(endTime);
    await axios
      .put(process.env.REACT_APP_API_ENDPOINT + "/sessions/", {
        id: id,
        startTime: start,
        endTime: end,
        SlotId: newSession.slotId,
        description: newSession.description,
        eventStatus: newSession.eventStatus,
        recurringEventId: newSession.recurringEventId,
        conferenceId: newSession.conferenceId,
        videoSavedLocation: newSession.videoSavedLocation,
        userTimeZone: selectedTimezone.value
          ? selectedTimezone.value
          : selectedTimezone,
      })
      .then((res) => {
        console.log("SUCCESSS Updated Session");
      })
      .catch((err) => {
        console.log("ERROR in Updation of Session");
        console.log(err);
      });
  };

  return (
    <div className="mt-7">
      <Container>
        <h1 className="pb-3">Session</h1>
        <Form className="form-session">
          <Form.Group className="mb-3 form-group-slot">
            <Form.Label className="form-label-slot">
              Choose your TimeZone
            </Form.Label>
            <TimezoneSelect
              value={selectedTimezone}
              onChange={setSelectedTimezone}
              timezones={timezones}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3 form-group-slot">
            <Form.Label className="form-label-slot">Select a Slot</Form.Label>
            <Form.Control
              as="select"
              value={newSession.slotId}
              onChange={(e) => {
                setNewSession({
                  slotId: e.target.value,
                  startTime: "",
                  endTime: "",
                  description: "",
                  eventStatus: "",
                  recurringEventId: "",
                  conferenceId: "",
                  videoSavedLocation: "",
                });

                setStartTime({ date: "", time: "" });
                setEndTime({ date: "", time: "" });
                getSessions(e.target.value);
              }}
              required
            >
              <option value={-1}>Choose One</option>
              {slots.map((names) => {
                return <option value={names.id}>{names.slotName}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Form>

        <Container>
          <Form>
            <Form.Group>
              <Row>
                <div id="sessions-list">
                  <ToggleButtonGroup type="radio" name="options" value={editId}>
                    {sessions.map((session) => {
                      return (
                        <ToggleButton
                          className="m-2"
                          value={session.id}
                          variant="outline-success"
                          onChange={() => {
                            setEditId(session.id);
                            setIsEdit(true);
                            setNewSessionValues(session.id);
                          }}
                        >
                          {moment
                            .tz(
                              session.startTime,
                              selectedTimezone.value
                                ? selectedTimezone.value
                                : selectedTimezone
                            )
                            .format("ddd, Do MMM YYYY, HH:mm A")}
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>
                </div>
              </Row>
            </Form.Group>
          </Form>
        </Container>

        {/* <Button
          className="ml-4 "
          variant="danger"
          onClick={(e) => {
            e.preventDefault();
            setTimeEdit(endTime);
          }}
        >
          Debug
        </Button> */}

        <Form
          className="form-session"
          onSubmit={(e) => {
            e.preventDefault();
            editSession(editId);
            setNewSession({
              slotId: -1,
              startTime: "",
              endTime: "",
              description: "",
              eventStatus: "",
              recurringEventId: "",
              conferenceId: "",
              videoSavedLocation: "",
            });
            setStartTime({ date: "", time: "" });
            setEndTime({ date: "", time: "" });
            setSessions([]);
            setEditId(() => -1);
            setIsEdit(false);
          }}
        >
          <br />
          <Row className="mb-2">
            <Col>
              <Form.Group className="mb-3 form-group-slot">
                <Form.Label className="form-label-slot">
                  Start Timing
                </Form.Label>
                <Form.Control
                  type="time"
                  value={startTime.time}
                  step="900"
                  onChange={(e) => {
                    let time = e.target.value;
                    let min = time.slice(3, 5);
                    let hr = time.slice(0, 2);
                    let numHr = Number(hr);
                    let numMin = Number(min);
                    let rem = numMin % 15;

                    if (rem % 15 === 0) {
                    } else if (rem % 15 > 7) {
                      numMin = numMin + (15 - rem);
                    } else {
                      numMin = numMin - rem;
                    }

                    if (numMin === 60) {
                      numMin = 0;
                      numHr = numHr + 1;
                      if (numHr === 24) {
                        hr = "00";
                      } else if (numHr < 10) {
                        hr = numHr.toString();
                        hr = 0 + hr;
                      } else {
                        hr = numHr.toString();
                      }
                    }

                    if (numMin < 10) {
                      min = numMin.toString();
                      min = 0 + min;
                    } else {
                      min = numMin.toString();
                    }

                    let val = replaceBetween(time, 0, 2, hr);
                    val = replaceBetween(val, 3, 5, min);

                    setStartTime({
                      ...startTime,
                      time: val,
                    });
                  }}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 form-group-slot">
                <Form.Label className="form-label-slot">Date</Form.Label>
                <Form.Control
                  required
                  type="date"
                  placeholder="Enter start date"
                  min={new Date().toISOString().split("T")[0]}
                  value={startTime.date}
                  onChange={(e) => {
                    setStartTime({
                      ...startTime,
                      date: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <Form.Group className="mb-3 form-group-slot">
                <Form.Label className="form-label-slot">End Timing</Form.Label>
                <Form.Control
                  type="time"
                  value={endTime.time}
                  step="900"
                  onChange={(e) => {
                    let time = e.target.value;
                    let min = time.slice(3, 5);
                    let hr = time.slice(0, 2);
                    let numHr = Number(hr);
                    let numMin = Number(min);
                    let rem = numMin % 15;

                    if (rem % 15 === 0) {
                    } else if (rem % 15 > 7) {
                      numMin = numMin + (15 - rem);
                    } else {
                      numMin = numMin - rem;
                    }

                    if (numMin === 60) {
                      numMin = 0;
                      numHr = numHr + 1;
                      if (numHr === 24) {
                        hr = "00";
                      } else if (numHr < 10) {
                        hr = numHr.toString();
                        hr = 0 + hr;
                      } else {
                        hr = numHr.toString();
                      }
                    }

                    if (numMin < 10) {
                      min = numMin.toString();
                      min = 0 + min;
                    } else {
                      min = numMin.toString();
                    }

                    let val = replaceBetween(time, 0, 2, hr);
                    val = replaceBetween(val, 3, 5, min);

                    setEndTime({
                      ...endTime,
                      time: val,
                    });
                  }}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 form-group-slot">
                <Form.Label className="form-label-slot">Date</Form.Label>
                <Form.Control
                  required
                  type="date"
                  placeholder="Enter end date"
                  value={endTime.date}
                  onChange={(e) => {
                    setEndTime({
                      ...endTime,
                      date: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3 form-group-service">
            <Form.Label className="form-label-service">Description</Form.Label>
            <Form.Control
              placeholder="Enter session description"
              value={newSession.description}
              onChange={(e) => {
                setNewSession({ ...newSession, description: e.target.value });
              }}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3 form-group-service">
            <Form.Label className="form-label-service">Event Status</Form.Label>
            <Form.Control
              required
              placeholder="Enter image"
              value={newSession.eventStatus}
              onChange={(e) => {
                setNewSession({ ...newSession, eventStatus: e.target.value });
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3 form-group-service">
            <Form.Label className="form-label-service">
              Recurring Event ID
            </Form.Label>
            <Form.Control
              placeholder="Enter description"
              value={newSession.recurringEventId}
              onChange={(e) => {
                setNewSession({
                  ...newSession,
                  recurringEventId: e.target.value,
                });
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3 form-group-service">
            <Form.Label className="form-label-service">
              Conference ID
            </Form.Label>
            <Form.Control
              required
              placeholder="Enter description"
              value={newSession.conferenceId}
              onChange={(e) => {
                setNewSession({
                  ...newSession,
                  conferenceId: e.target.value,
                });
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3 form-group-service">
            <Form.Label className="form-label-service">
              Video Saved Location
            </Form.Label>
            <Form.Control
              placeholder="Enter Video Saved Location"
              value={newSession.videoSavedLocation}
              onChange={(e) => {
                setNewSession({
                  ...newSession,
                  videoSavedLocation: e.target.value,
                });
              }}
            />
          </Form.Group>

          <Button
            className={"ml-4 " + (IsEdit ? "d-inline-block" : "d-none")}
            variant="primary"
            type="submit"
          >
            Save changes
          </Button>
          <Button
            className={"ml-4 " + (IsEdit ? "d-inline-block" : "d-none")}
            variant="danger"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              setNewSession({
                slotId: -1,
                startTime: "",
                endTime: "",
                description: "",
                eventStatus: "",
                recurringEventId: "",
                conferenceId: "",
                videoSavedLocation: "",
              });
              setStartTime({ date: "", time: "" });
              setEndTime({ date: "", time: "" });
              setSessions([]);
              setEditId(() => -1);
              setIsEdit(false);
            }}
          >
            Cancel
          </Button>
        </Form>
      </Container>
    </div>
  );
}

export default Session;
