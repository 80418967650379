import React, { useMemo } from 'react';
import { Container } from 'reactstrap';
import TableContainer from './TableContainer';
import 'bootstrap/dist/css/bootstrap.min.css';
import makeData from './makeData';
import { SliderColumnFilter, filterLessThan } from './filters';

const ReactTable = ({ recording, timezone, meet }) => {
  const data = React.useMemo(
    () => makeData(recording, timezone, meet),
    [recording, timezone, meet],
  );

  const columns = useMemo(
    () => [
      {
        maxWidth: 400,
        minWidth: 130,
        width: 130,
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        maxWidth: 400,
        minWidth: 340,
        width: 340,
        Header: 'Batch',
        accessor: 'batch',
      },
      {
        maxWidth: 200,
        minWidth: 120,
        width: 120,
        Header: 'Start Time',
        accessor: 'starttime',
        disableFilters: true,
      },
      {
        maxWidth: 200,
        minWidth: 120,
        width: 120,
        Header: 'End Time',
        accessor: 'endtime',
        disableFilters: true,
      },
      {
        maxWidth: 400,
        minWidth: 180,
        width: 180,
        Header: 'Duration (mins)',
        accessor: 'duration',
        Filter: SliderColumnFilter,
        filter: filterLessThan,
      },

      {
        maxWidth: 400,
        minWidth: 100,
        width: 100,
        Header: 'Video Link',
        accessor: 'videolink',
        disableFilters: true,
      },
    ],
    [],
  );

  return (
    <Container style={{ marginLeft: '2rem' }}>
      <TableContainer columns={columns} data={data} />
    </Container>
  );
};

export default ReactTable;
