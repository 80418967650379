import React from "react";
import { Button } from "react-bootstrap";
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="not-found">
      <span>
        <h1 className="not-found-heading">OOPS!</h1>
        <div className="not-found-msg">
          We can't find the page you're looking for. Reach out to us for help or
          head back to home.
        </div>
        <div>
          <Button href="/" className="not-found-btn">
            Home
          </Button>
          <Button href="/contact" className="not-found-btn">
            Help
          </Button>
        </div>
      </span>
    </div>
  );
};

export default NotFound;
