import React, { useEffect, useState } from "react";
import { Container, Row, Button, Form, Col } from "react-bootstrap";
import SlotCard from "./SlotCard/SlotCard";
import axios from "axios";
import TimezoneSelect from "react-timezone-select";
import "./Slot.css";
const moment = require("moment");

const timezones = {
  "America/Chicago": "Central Time",
  "America/Los_Angeles": "Pacific Time",
  "America/Boise": "Mountain Time",
  "America/New_York": "Eastern Time",
  "Asia/Kolkata": "Chennai, Kolkata, Mumbai, New Delhi",
};

const dayToNum = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
};

function replaceBetween(origin, startIndex, endIndex, insertion) {
  return (
    origin.substring(0, startIndex) + insertion + origin.substring(endIndex)
  );
}

const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function Slot() {
  const [SlotCards, setSlotCards] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [isCreateTime, setIsCreateTime] = useState(true);
  const [editId, setEditId] = useState(-1);
  const [serviceNames, setServiceNames] = useState([]);
  const [slotTimings, setSlotTimings] = useState([]);
  const [slotTimeEdit, setSlotTimeEdit] = useState({
    dayOfWeek: "",
    timeOfDay: "",
  });
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [newSlotTiming, setNewSlotTiming] = useState({
    dayOfWeek: "",
    timeOfDay: "",
  });
  const [newSlot, setNewSlot] = useState({
    serviceID: "",
    slotName: "",
    slotDescription: "",
    capacity: "",
    startDate: "",
    endDate: "",
    duration: "",
    userTimeZone: "",
    numSessions: "",
    conferenceId: "",
  });

  const [sessionsList, setSessionsList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await getSlots();
      await getServiceNames();
    };
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    showSessions();
  }, [newSlot.duration, newSlot.startDate, newSlot.endDate, slotTimings]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSlots = async () => {
    await axios
      .get(process.env.REACT_APP_API_ENDPOINT + "/slots/")
      .then((res) => {
        setSlotCards(res.data);
      });
  };

  const getServiceNames = async () => {
    await axios
      .get(process.env.REACT_APP_API_ENDPOINT + "/services/")
      .then((res) => {
        setServiceNames(res.data);
      });
  };

  const addSlotTimings = (e) => {
    e.preventDefault();
    setSlotTimings([...slotTimings, newSlotTiming]);
    setNewSlotTiming({ dayOfWeek: "", timeOfDay: "" });
  };

  const createSessionsForSlot = async (sessionslist, id) => {
    await axios
      .post(process.env.REACT_APP_API_ENDPOINT + "/sessions/createforaslot", {
        startDate: newSlot.startDate,
        endDate: newSlot.endDate,
        description: newSlot.slotDescription,
        duration: newSlot.duration,
        userTimeZone: selectedTimezone.value
          ? selectedTimezone.value
          : selectedTimezone,
        conferenceId: newSlot.conferenceId,
        sessionslist: sessionslist,
        SlotId: id,
      })
      .then((res) => {
        console.log("New Session created in frontend");
      })
      .catch((error) => {
        console.log("ERROR IN FRONTEND create sessions for Slot");
        console.log(error);
      })
      .finally(() => {
        console.log("finally IN sessioneach");
      });
  };

  const createSlot = async (e) => {
    e.preventDefault();
    let sessionslist = getSessions(
      moment(newSlot.startDate).clone(),
      moment(newSlot.endDate).clone()
    );
    await axios
      .post(process.env.REACT_APP_API_ENDPOINT + "/slots/", {
        serviceID: newSlot.serviceID,
        slotName: newSlot.slotName,
        slotDescription: newSlot.slotDescription,
        capacity: newSlot.capacity,
        startDate: newSlot.startDate,
        endDate: newSlot.endDate,
        duration: newSlot.duration,
        numSessions: sessionslist.length,
        conferenceId: newSlot.conferenceId,
        userTimeZone: selectedTimezone.value
          ? selectedTimezone.value
          : selectedTimezone,
        slotTimings: slotTimings,
      })
      .then((res) => {
        console.log("New Slot created in frontend");
        createSessionsForSlot(sessionslist, res.data.id);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        getSlots();
        getServiceNames();
      });
  };

  const editSlot = async (id) => {
    let sessionslist = getSessions(
      moment(newSlot.startDate).clone(),
      moment(newSlot.endDate).clone()
    );
    await axios
      .put(process.env.REACT_APP_API_ENDPOINT + "/slots/", {
        id: id,
        serviceID: newSlot.serviceID,
        slotName: newSlot.slotName,
        slotDescription: newSlot.slotDescription,
        capacity: newSlot.capacity,
        startDate: newSlot.startDate,
        endDate: newSlot.endDate,
        duration: newSlot.duration,
        userTimeZone: selectedTimezone.value
          ? selectedTimezone.value
          : selectedTimezone,
        numSessions: sessionslist.length,
        conferenceId: newSlot.conferenceId,
        slotTimings: slotTimings,
      })

      .then(async (res) => {
        console.log("Slot data edited successfully");
        createSessionsForSlot(sessionslist, id);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        getSlots();
        getServiceNames();
      });
  };

  // GET SlotTimings associated with SLot ID 'id'
  const getSlotTimings = async (id) => {
    await axios
      .post(process.env.REACT_APP_API_ENDPOINT + "/slot-timing/show", {
        id: id,
      })
      .then((res) => {
        setSlotTimings(res.data);
      })
      .catch((err) => {
        console.log("ERROR in GET in Slot Timings");
        console.log(err);
      });
  };

  // Returns an array of moment objects with startTime of all sessions for a Slot.
  const getSessions = (startDate, endDate) => {
    const result = [];
    startDate.tz("Africa/Accra", true);
    endDate.set({ hour: 23, minute: 59 });
    for (let ele in slotTimings) {
      let dayNum;
      dayNum = dayToNum[slotTimings[ele].dayOfWeek];
      let time = slotTimings[ele].timeOfDay;
      let hr = time.slice(0, 2);
      let mnt = time.slice(3, 5);
      let current = moment.utc(startDate.clone());

      if (current.weekday() !== dayNum) {
        current.add(
          dayNum >= current.weekday()
            ? dayNum - current.weekday()
            : 7 - (current.weekday() - dayNum),
          "day"
        );
      }
      current.set({ hour: hr, minute: mnt });
      while (current.isSameOrBefore(endDate)) {
        current.set({ hour: hr, minute: mnt });
        result.push(current.clone());

        current.add(7, "day");
      }
    }
    result.sort((a, b) => a.valueOf() - b.valueOf());
    setNewSlot({ ...newSlot, numSessions: result.length });

    return result;
  };

  const showSessions = () => {
    if (
      newSlot.endDate &&
      newSlot.startDate &&
      newSlot.duration &&
      slotTimings.length !== 0
    ) {
      var result_list = [];
      const newarr = getSessions(
        moment(newSlot.startDate).clone(),
        moment(newSlot.endDate).clone()
      );
      for (let ele in newarr) {
        let endTime = moment(newarr[ele]).add(newSlot.duration, "minutes");
        let temp =
          newarr[ele].format("ddd, Do MMM YYYY, HH:mm A") +
          " -- " +
          endTime.format("HH:mm A");
        result_list.push(temp);
      }
      setSessionsList(result_list);
    } else {
      setSessionsList([]);
    }
  };

  const edit = (
    id,
    capacity,
    slotName,
    slotDescription,
    startDate,
    endDate,
    duration,
    userTimeZone,
    numSessions,
    conferenceId,
    serviceID
  ) => {
    setEditId(id);
    setNewSlot({
      serviceID: serviceID,
      slotName: slotName,
      slotDescription: slotDescription,
      capacity: capacity,
      startDate: startDate.split("T")[0],
      endDate: endDate.split("T")[0],
      duration: duration,
      userTimeZone: userTimeZone,
      numSessions: numSessions,
      conferenceId: conferenceId,
    });
    setSelectedTimezone(userTimeZone);
    getSlotTimings(id);
    setIsCreate(false);
  };

  return (
    <div className="mt-7">
      <Container>
        <h1 className="pb-3">Batch</h1>
        <Row>
          <Col className="col-9 p-0">
            <Form
              className="form-slot"
              onSubmit={(e) => {
                e.preventDefault();

                setNewSlot({
                  ...newSlot,
                  userTimeZone: selectedTimezone.value
                    ? selectedTimezone.value
                    : selectedTimezone,
                });

                createSlot(e);
                setNewSlot({
                  serviceID: "",
                  slotName: "",
                  slotDescription: "",
                  capacity: "",
                  startDate: "",
                  endDate: "",
                  duration: "",
                  userTimeZone: "",
                  numSessions: "",
                  conferenceId: "",
                });
                setSelectedTimezone(
                  Intl.DateTimeFormat().resolvedOptions().timeZone
                );
                setNewSlotTiming({ dayOfWeek: "", timeOfDay: "" });
                setSlotTimings([]);
                setSessionsList([]);
              }}
            >
              <Form.Group className="mb-3 form-group-slot">
                <Form.Label className="form-label-slot">
                  Select a Service
                </Form.Label>
                <Form.Control
                  as="select"
                  value={newSlot.serviceID}
                  onChange={(e) => {
                    setNewSlot({ ...newSlot, serviceID: e.target.value });
                  }}
                  required
                >
                  <option value={""}>Choose One</option>
                  {serviceNames.map((names) => {
                    return <option value={names.id}>{names.name}</option>;
                  })}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3 form-group-slot">
                <Form.Label className="form-label-slot">Slot Name</Form.Label>
                <Form.Control
                  required
                  placeholder="Enter Slot Name"
                  value={newSlot.slotName}
                  onChange={(e) => {
                    setNewSlot({ ...newSlot, slotName: e.target.value });
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3 form-group-slot">
                <Form.Label className="form-label-slot">
                  Slot Description
                </Form.Label>
                <Form.Control
                  required
                  placeholder="Enter Slot description"
                  as="textarea"
                  rows={2}
                  value={newSlot.slotDescription}
                  onChange={(e) => {
                    setNewSlot({ ...newSlot, slotDescription: e.target.value });
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3 form-group-slot">
                <Form.Label className="form-label-slot">Capacity</Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  placeholder="Enter the Student capacity"
                  value={newSlot.capacity}
                  onChange={(e) => {
                    setNewSlot({ ...newSlot, capacity: e.target.value });
                  }}
                  required
                />
              </Form.Group>

              <Form.Row className="mb-4">
                <Col>
                  <Form.Group className="mb-3 form-group-slot">
                    <Form.Label className="form-label-slot">
                      Start Date
                    </Form.Label>
                    <Form.Control
                      required
                      type="date"
                      placeholder="Enter start date"
                      min={moment().format().split("T")[0]}
                      value={newSlot.startDate}
                      onChange={(e) => {
                        setNewSlot({ ...newSlot, startDate: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 form-group-slot">
                    <Form.Label className="form-label-slot">
                      End Date
                    </Form.Label>
                    <Form.Control
                      type="date"
                      min={newSlot.startDate}
                      placeholder="Enter end date"
                      value={newSlot.endDate}
                      onChange={(e) => {
                        setNewSlot({ ...newSlot, endDate: e.target.value });
                      }}
                      required
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <div>
                <h4>Choose Slot Timings</h4>
                <Form>
                  <Form.Row>
                    <Col>
                      <Form.Group className="mb-3 form-group-slot">
                        <Form.Label className="form-label-slot">
                          Slot Timing
                        </Form.Label>
                        <Form.Control
                          type="time"
                          value={newSlotTiming.timeOfDay}
                          step="900"
                          onChange={(e) => {
                            let time = e.target.value;
                            let min = time.slice(3, 5);
                            let hr = time.slice(0, 2);
                            let numHr = Number(hr);
                            let numMin = Number(min);
                            let rem = numMin % 15;

                            if (rem % 15 === 0) {
                            } else if (rem % 15 > 7) {
                              numMin = numMin + (15 - rem);
                            } else {
                              numMin = numMin - rem;
                            }

                            if (numMin === 60) {
                              numMin = 0;
                              numHr = numHr + 1;
                              if (numHr === 24) {
                                hr = "00";
                              } else if (numHr < 10) {
                                hr = numHr.toString();
                                hr = 0 + hr;
                              } else {
                                hr = numHr.toString();
                              }
                            }

                            if (numMin < 10) {
                              min = numMin.toString();
                              min = 0 + min;
                            } else {
                              min = numMin.toString();
                            }

                            let val = replaceBetween(time, 0, 2, hr);
                            val = replaceBetween(val, 3, 5, min);

                            setNewSlotTiming({
                              ...newSlotTiming,
                              timeOfDay: val,
                            });
                          }}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3 form-group-slot">
                        <Form.Label className="form-label-slot">
                          Select a Day
                        </Form.Label>
                        <Form.Control
                          as="select"
                          value={newSlotTiming.dayOfWeek}
                          onChange={(e) => {
                            setNewSlotTiming({
                              ...newSlotTiming,
                              dayOfWeek: e.target.value,
                            });
                          }}
                          required
                        >
                          <option value={""}>Choose One</option>
                          {weekDays.map((day) => {
                            return <option>{day}</option>;
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>

                    <Button
                      className={
                        "button-slot-timing " +
                        (isCreateTime ? "d-inline-block" : "d-none")
                      }
                      variant="primary"
                      disabled={
                        !newSlotTiming.dayOfWeek || !newSlotTiming.timeOfDay
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        addSlotTimings(e);
                      }}
                    >
                      +
                    </Button>
                    <Button
                      className={
                        "button-slot-timing " +
                        (isCreateTime ? "d-none" : "d-inline-block")
                      }
                      variant="success"
                      disabled={
                        !newSlotTiming.dayOfWeek || !newSlotTiming.timeOfDay
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        let ans;
                        ans = slotTimings.map((slotTiming, idx, arr) => {
                          if (slotTiming.dayOfWeek === slotTimeEdit.dayOfWeek) {
                            if (
                              slotTiming.timeOfDay === slotTimeEdit.timeOfDay
                            ) {
                              slotTiming.dayOfWeek = newSlotTiming.dayOfWeek;
                              slotTiming.timeOfDay = newSlotTiming.timeOfDay;
                            }
                          }
                          return slotTiming;
                        });
                        setSlotTimings(ans);
                        setNewSlotTiming({ dayOfWeek: "", timeOfDay: "" });
                        setSlotTimeEdit({ dayOfWeek: "", timeOfDay: "" });
                        setIsCreateTime(true);
                        showSessions();
                      }}
                    >
                      Save
                    </Button>

                    <Button
                      className={
                        "button-slot-timing mx-1 " +
                        (isCreateTime ? "d-none" : "d-inline-block")
                      }
                      variant="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        setNewSlotTiming({ dayOfWeek: "", timeOfDay: "" });
                        setSlotTimeEdit({ dayOfWeek: "", timeOfDay: "" });
                        setIsCreateTime(true);
                      }}
                    >
                      Cancel
                    </Button>
                  </Form.Row>
                </Form>

                <Col>
                  <div className="slotTiming-box-group">
                    {slotTimings.map((slotTiming) => {
                      return (
                        <div className="slotTiming-box">
                          <h6>
                            {slotTiming.dayOfWeek} -- {slotTiming.timeOfDay}
                          </h6>
                          <Button
                            className="answers-box-button"
                            variant="info"
                            onClick={(e) => {
                              e.preventDefault();
                              setIsCreateTime(false);
                              newSlotTiming.dayOfWeek = slotTiming.dayOfWeek;
                              newSlotTiming.timeOfDay = slotTiming.timeOfDay;
                              setSlotTimeEdit(slotTiming);
                            }}
                          >
                            /
                          </Button>

                          <Button
                            variant="danger"
                            className="answers-box-button"
                            onClick={(e) => {
                              e.preventDefault();
                              setSlotTimings(
                                slotTimings.filter(function (slotTime) {
                                  if (
                                    slotTiming.dayOfWeek === slotTime.dayOfWeek
                                  ) {
                                    if (
                                      slotTiming.timeOfDay ===
                                      slotTime.timeOfDay
                                    ) {
                                      return false;
                                    }
                                  }
                                  return true;
                                })
                              );
                            }}
                          >
                            X
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </div>
              <Col>
                <Form.Group className="mb-3 form-group-slot">
                  <Form.Label className="form-label-slot">
                    Choose your TimeZone
                  </Form.Label>
                  <TimezoneSelect
                    value={selectedTimezone}
                    onChange={setSelectedTimezone}
                    timezones={timezones}
                    required
                  />
                </Form.Group>
              </Col>
              <Form.Row>
                <Col>
                  <Form.Group className="mb-3 form-group-slot">
                    <Form.Label className="form-label-slot">
                      Select a Duration
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={newSlot.duration}
                      onChange={(e) => {
                        setNewSlot({
                          ...newSlot,
                          duration: e.target.value,
                          userTimeZone: selectedTimezone.value
                            ? selectedTimezone.value
                            : selectedTimezone,
                        });
                      }}
                      required
                    >
                      <option value={""}>Choose duration(in minutes)</option>
                      <option value={30}>30</option>
                      <option value={45}>45</option>
                      <option value={60}>60</option>
                      <option value={75}>75</option>
                      <option value={90}>90</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 form-group-slot">
                    <Form.Label className="form-label-slot">
                      Number of Sessions
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min="1"
                      readOnly
                      placeholder="0"
                      value={newSlot.numSessions}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Group className="mb-3 form-group-slot">
                <Form.Label className="form-label-slot">
                  Conference ID
                </Form.Label>
                <Form.Control
                  required
                  placeholder="Enter conference ID"
                  value={newSlot.conferenceId}
                  onChange={(e) => {
                    setNewSlot({ ...newSlot, conferenceId: e.target.value });
                  }}
                />
              </Form.Group>

              <Button
                className={"ml-4 " + (isCreate ? "d-inline-block" : "d-none")}
                variant="primary"
                type="submit"
                disabled={slotTimings.length > 0 ? false : true}
              >
                Create a New Slot
              </Button>

              <Button
                className={"ml-4 " + (isCreate ? "d-none" : "d-inline-block")}
                variant="primary"
                type="submit"
                disabled={slotTimings.length > 0 ? false : true}
                onClick={(e) => {
                  e.preventDefault();
                  if (selectedTimezone.value === undefined) {
                    setNewSlot({
                      ...newSlot,
                      userTimeZone: selectedTimezone,
                    });
                  } else {
                    setNewSlot({
                      ...newSlot,
                      userTimeZone: selectedTimezone.value,
                    });
                  }

                  editSlot(editId);
                  setNewSlot({
                    serviceID: "",
                    slotName: "",
                    slotDescription: "",
                    capacity: "",
                    startDate: "",
                    endDate: "",
                    duration: "",
                    userTimeZone: "",
                    numSessions: "",
                    conferenceId: "",
                  });
                  setSlotTimings([]);
                  setNewSlotTiming({ dayOfWeek: "", timeOfDay: "" });
                  setSelectedTimezone(
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  );
                  setEditId(-1);
                  setIsCreate(true);
                  setSessionsList([]);
                }}
              >
                Save changes
              </Button>

              <Button
                className={"ml-4 " + (isCreate ? "d-none" : "d-inline-block")}
                variant="danger"
                onClick={(e) => {
                  e.preventDefault();
                  setEditId(-1);
                  setNewSlot({
                    serviceID: "",
                    slotName: "",
                    slotDescription: "",
                    capacity: "",
                    startDate: "",
                    endDate: "",
                    duration: "",
                    userTimeZone: "",
                    numSessions: "",
                    conferenceId: "",
                  });
                  setNewSlotTiming({ dayOfWeek: "", timeOfDay: "" });
                  setSelectedTimezone(
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  );
                  setSlotTimings([]);
                  setIsCreate(true);
                  setSessionsList([]);
                }}
              >
                Cancel
              </Button>
            </Form>
          </Col>
          <Col className="col-3 p-0">
            <h4 className="form-group-slot">Hello Sessions-list</h4>
            {sessionsList.map((sess) => {
              return <h6 className="session-list-item">{sess}</h6>;
            })}
          </Col>
        </Row>

        <br />
        <br />
        <Row>
          {SlotCards.map((slotCard) => {
            return (
              <SlotCard
                key={slotCard.id}
                id={slotCard.id}
                serviceID={slotCard.ServiceId}
                slotName={slotCard.slotName}
                slotDescription={slotCard.slotDescription}
                capacity={slotCard.studentCapacity}
                startDate={slotCard.startDate}
                endDate={slotCard.endDate}
                duration={slotCard.duration}
                userTimeZone={slotCard.userTimeZone}
                numSessions={slotCard.numSessions}
                conferenceId={slotCard.conferenceId}
                edit={edit}
              />
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default Slot;
