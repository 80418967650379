import React,{useState} from "react";
import { Button, Col, Form, Spinner,Row,Table } from "react-bootstrap";
import Conflict from "./Conflict";
import moment from "moment-timezone";
import axios from "axios";

const Response = ({ response, loading, search,zoomSlotChecker,topic }) => {
  const [email, setEmail] = useState('')
  
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.get(process.env.REACT_APP_API_ENDPOINT+ '/services').then(res => {
      const service = res.data.filter(data => data.id === parseInt(topic.service))
      zoomSlotChecker({
        type: 2,
        account: email,
        input: response.input,
        topic: `${service[0].name} ${topic.slot}-`
      })
    }).catch(err => console.log(err))
  }

  return (
    <Col className="text-center px-4">
      {response && response.type === "check" && (
        <span className="text-left">
          <div>
            <h5>
              <b>Available Accounts</b>
            </h5>
            {search === "slot" ? (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <div className="mb-3">
                      {response.available.map((acc) =>
                        <Form.Check 
                        key={acc}
                        type="radio"
                        name="account"
                        label={acc}
                        onChange={() => setEmail(acc)}
                      />
                      )}
                    </div>
                    <span className="m-3">
                      <Button variant="dark" type="submit">Schedule Now</Button>
                    </span>
                  </Row>
                </Form>
              ) : (
                response.available.map((acc, index) =>
                  <li key={index} className="mb-1">
                  {acc}
                </li>
                )
              )}
          </div>
          <Conflict conflict={response.conflict} />
        </span>
      )}
      {response && response.type === "schedule" && search === "slot" && <Table responsive="true">
        <thead>
          <tr>
            <th className="px-1">Topic</th>
            <th className="px-1">Date</th>
            <th className="px-1">Time</th>
            <th className="px-1">Dur.</th>
            <th className="px-1">Host Url</th>
            <th className="px-1">Participant Url</th>
          </tr>
        </thead>
        <tbody className="text-left">
          {response.urls.map((url,index) => (
            <tr key={index}>
              <td className="px-1">{url.topic}</td>
              <td className="px-1">
                {moment(url.start_time)
                  .tz(url.timezone)
                  .format("DD MMM YYYY")}
              </td>
              <td className="px-1">
                {moment(url.start_time)
                  .tz(url.timezone)
                  .format("HH : mm A")}
              </td>
              <td className="px-1">{url.duration}</td>
              <td className="px-1 text-break " width="600px"><a href={url.host_url}>{url.host_url}</a></td>
              <td className="px-1 text-break" width="200px"><a href={url.participant_url}>{url.participant_url}</a></td>
            </tr>
          ))}
        </tbody>
      </Table>}
      
      {loading && (
        <Spinner animation="border" role="status" className="mt-7">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
    </Col>
  );
};

export default Response;
