import React, { useState, useEffect } from "react";
import { Container, Row, Button, Form, Col } from "react-bootstrap";
import ServiceCard from "./ServiceCard/ServiceCard";
import axios from "axios";
import "./Service.css";

const categoryNames = ["Tech", "Math", "Language"];
const paymentTypes = ["payment", "subscription"];
const questionTypes = ["string", "single-select", "multi-select"];
function Service() {
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState({
    text: "",
    type: "",
    answer: [],
    required: true,
  });
  const [answers, setAnswers] = useState([]);
  const [newAnswer, setNewAnswer] = useState("");
  const [isCreateAnswer, setIsCreateAnswer] = useState(true); //isCreateTime
  const [isCreateQuestion, setIsCreateQuestion] = useState(true);
  const [serviceCards, setServiceCards] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [editId, setEditId] = useState(-1);
  const [answerEdit, setAnswerEdit] = useState("");
  const [questionEdit, setQuestionEdit] = useState({
    text: "",
    type: "",
    answer: [],
    required: true,
  });
  const [newService, setNewService] = useState({
    name: "",
    image: "",
    description: "",
    priceId: "",
    category: "",
    paymentType: "",
  });

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    setNewQuestion({
      ...newQuestion,
      answer: answers,
    });
  }, [answers]); // eslint-disable-line react-hooks/exhaustive-deps

  const createQuestionsForService = async (questions, id) => {
    await axios
      .post(process.env.REACT_APP_API_ENDPOINT + "/questions/", {
        questions: questions,
        id: id,
      })
      .then((res) => {
        console.log("Questions for a Service Created");
      })
      .then((error) => {
        console.log("ERROR in creating questions for a Service");
      });
  };

  const getQuestions = async (reqId) => {
    await axios
      .get(process.env.REACT_APP_API_ENDPOINT + "/questions/", {
        params: {
          id: reqId,
        },
      })
      .then((res) => {
        setQuestions(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        console.log("ERROR in GET Questions");
        console.log(error);
      });
  };

  const getServices = async (e) => {
    await axios
      .get(process.env.REACT_APP_API_ENDPOINT + "/services/")
      .then((res) => {
        setServiceCards(res.data);
      });
  };

  const createService = async (e) => {
    e.preventDefault();
    await axios
      .post(process.env.REACT_APP_API_ENDPOINT + "/services/", {
        name: newService.name,
        image: newService.image,
        description: newService.description,
        priceId: newService.priceId,
        category: newService.category,
        paymentType: newService.paymentType,
      })
      .then((res) => {
        console.log("Data created in frontend");
        createQuestionsForService(questions, res.data.id);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        getServices();
      });
  };

  const editService = async (id) => {
    await axios
      .put(process.env.REACT_APP_API_ENDPOINT + "/services/", {
        id: id,
        name: newService.name,
        image: newService.image,
        description: newService.description,
        priceId: newService.priceId,
        category: newService.category,
        paymentType: newService.paymentType,
      })
      .then((res) => {
        console.log("User data edited successfully");
        createQuestionsForService(questions, id);
      })
      .catch((error) => {
        console.log("ERROR IN EditService");
        console.log(error);
      })
      .finally(() => {
        getServices();
      });
  };

  const edit = (
    id,
    name,
    image,
    description,
    priceId,
    paymentType,
    category
  ) => {
    setEditId(id);
    setNewService({
      name: name,
      image: image,
      description: description,
      priceId: priceId,
      category: category,
      paymentType: paymentType,
    });
    getQuestions(id);
    setIsCreate(false);
  };

  return (
    <div className="mt-7">
      <Container>
        <h1 className="pb-3">Service</h1>
        <Row>
          <Col className="col-9 p-0">
            <Form
              className="form-service"
              onSubmit={(e) => {
                createService(e);
                setNewService({
                  name: "",
                  image: "",
                  description: "",
                  priceId: "",
                  category: "",
                  paymentType: "",
                });

                setQuestions([]);
                setAnswers([]);
              }}
            >
              <Form.Group className="mb-3 form-group-service">
                <Form.Label className="form-label-service">
                  Service name
                </Form.Label>
                <Form.Control
                  required
                  placeholder="Enter service name"
                  value={newService.name}
                  onChange={(e) => {
                    setNewService({ ...newService, name: e.target.value });
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3 form-group-service">
                <Form.Label className="form-label-service">Image</Form.Label>
                <Form.Control
                  required
                  placeholder="Enter image"
                  value={newService.image}
                  onChange={(e) => {
                    setNewService({ ...newService, image: e.target.value });
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3 form-group-service">
                <Form.Label className="form-label-service">
                  Description
                </Form.Label>
                <Form.Control
                  required
                  placeholder="Enter description"
                  value={newService.description}
                  onChange={(e) => {
                    setNewService({
                      ...newService,
                      description: e.target.value,
                    });
                  }}
                />
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group className="mb-3 form-group-service">
                    <Form.Label className="form-label-service payment-type-label">
                      Select a Payment Type
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={newService.paymentType}
                      required
                      onChange={(e) => {
                        setNewService({
                          ...newService,
                          paymentType: e.target.value,
                        });
                      }}
                    >
                      <option value={""}>Choose One</option>
                      {paymentTypes.map((name) => {
                        return (
                          <option value={name} key={name}>
                            {name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3 form-group-service">
                    <Form.Label className="form-label-service">
                      Price ID
                    </Form.Label>
                    <Form.Control
                      required={true}
                      placeholder="Enter Price ID"
                      value={newService.priceId}
                      onChange={(e) => {
                        setNewService({
                          ...newService,
                          priceId: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>{" "}
              </Row>

              <Form.Group className="mb-3 form-group-service">
                <Form.Label className="form-label-service">
                  Select a Category
                </Form.Label>
                <Form.Control
                  as="select"
                  value={newService.category}
                  onChange={(e) => {
                    setNewService({ ...newService, category: e.target.value });
                  }}
                  required
                >
                  <option value={""}>Choose One</option>
                  {categoryNames.map((name) => {
                    return (
                      <option value={name} key={name}>
                        {name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>

              <h4>Add a question</h4>

              <Form.Group className="mb-3 form-group-service">
                <Form.Label className="form-label-service">Question</Form.Label>
                <Form.Control
                  placeholder="Enter a question"
                  value={newQuestion.text}
                  onChange={(e) => {
                    setNewQuestion({
                      ...newQuestion,
                      text: e.target.value,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3 form-group-service">
                <Form.Label className="form-label-service">
                  Select a Question Type
                </Form.Label>
                <Form.Control
                  as="select"
                  value={newQuestion.type}
                  onChange={(e) => {
                    setNewQuestion({
                      ...newQuestion,
                      type: e.target.value,
                    });
                  }}
                >
                  <option value={""}>Choose One</option>
                  {questionTypes.map((name) => {
                    return (
                      <option value={name} key={name}>
                        {name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3 form-group-service">
                <Form.Check type={"checkbox"}>
                  <Form.Check.Input
                    type={"checkbox"}
                    className="form-label-service"
                    checked={newQuestion.required}
                    onChange={(e) => {
                      setNewQuestion({
                        ...newQuestion,
                        required: e.target.checked,
                      });
                    }}
                  />
                  <Form.Check.Label>Is Answer Required</Form.Check.Label>
                </Form.Check>
              </Form.Group>

              <Row
                className={
                  "answer-form " +
                  (newQuestion.type === "single-select" ||
                  newQuestion.type === "multi-select"
                    ? "d-inline-flex "
                    : "d-none ")
                }
              >
                <Col className="col-11 p-0">
                  {" "}
                  <Form.Group className="mb-3 form-group-service">
                    <Form.Label className="form-label-service">
                      Answer
                    </Form.Label>
                    <Form.Control
                      placeholder="Enter an answer"
                      value={newAnswer}
                      onChange={(e) => {
                        setNewAnswer(() => e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col className="col-1 p-0">
                  <Button
                    className={
                      "button-answer " +
                      (isCreateAnswer ? "d-inline-block " : "d-none ")
                    }
                    id="button-answer"
                    variant="primary"
                    disabled={!newAnswer}
                    onClick={(e) => {
                      e.preventDefault();
                      setAnswers([...answers, newAnswer]);
                      setNewAnswer("");
                    }}
                  >
                    +
                  </Button>
                  <div id="answer-edit-button-group">
                    <Button
                      className={
                        "button-answer " +
                        (isCreateAnswer ? "d-none " : "d-inline-block ")
                      }
                      variant="success"
                      disabled={!newAnswer}
                      onClick={(e) => {
                        e.preventDefault();

                        let answersarr = answers.filter(function (ansinarr) {
                          if (answerEdit === ansinarr) {
                            return false;
                          }
                          return true;
                        });

                        setAnswers([...answersarr, newAnswer]);

                        setNewAnswer("");
                        setAnswerEdit("");
                        setIsCreateAnswer(true);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      className={
                        "button-answer mx-1 " +
                        (isCreateAnswer ? "d-none " : "d-inline-block ")
                      }
                      variant="danger"
                      onClick={(e) => {
                        e.preventDefault();
                        setNewAnswer("");
                        setAnswerEdit("");
                        setIsCreateAnswer(true);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
                <Col>
                  <div className="answers-box-group">
                    {answers.map((ans) => {
                      return (
                        <div className="answers-box" key={ans}>
                          <h6>{ans}</h6>
                          <Button
                            className="slotTiming-box-button"
                            variant="info"
                            onClick={(e) => {
                              e.preventDefault();
                              setIsCreateAnswer(false);
                              setNewAnswer(() => ans);
                              setAnswerEdit(() => ans);
                            }}
                          >
                            /
                          </Button>
                          <Button
                            variant="danger"
                            className="slotTiming-box-button"
                            onClick={(e) => {
                              e.preventDefault();
                              setAnswers(
                                answers.filter(function (ansinarr) {
                                  if (ans === ansinarr) {
                                    return false;
                                  }
                                  return true;
                                })
                              );
                            }}
                          >
                            X
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>

              <div id="add-question-btn">
                <Button
                  disabled={
                    newQuestion.type !== "string"
                      ? answers.length === 0
                        ? true
                        : newQuestion.text === ""
                        ? true
                        : false
                      : newQuestion.text === ""
                      ? true
                      : false
                  }
                  className={
                    "mb-5 " + (isCreateQuestion ? "d-inline-block" : "d-none")
                  }
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setNewQuestion({ ...newQuestion, answer: answers });
                    setAnswers([]);
                    setQuestions([...questions, newQuestion]);

                    setNewQuestion({
                      text: "",
                      type: "",
                      answer: [],
                      required: true,
                    });
                  }}
                >
                  Add Question
                </Button>
              </div>
              <Button
                className={
                  "button-answer mb-5 " +
                  (isCreateQuestion ? "d-none " : "d-inline-block ")
                }
                variant="success"
                disabled={!newQuestion.type || !newQuestion.text}
                onClick={(e) => {
                  e.preventDefault();

                  let questionsarr = questions.filter(function (quesinarr) {
                    if (
                      questionEdit.text === quesinarr.text &&
                      questionEdit.type === quesinarr.type &&
                      questionEdit.answer.sort() === quesinarr.answer.sort()
                    ) {
                      return false;
                    }
                    return true;
                  });

                  setQuestions([...questionsarr, newQuestion]);
                  setNewQuestion({
                    text: "",
                    type: "",
                    answer: [],
                    required: true,
                  });
                  setQuestionEdit({
                    text: "",
                    type: "",
                    answer: [],
                    required: true,
                  });
                  setAnswers([]);
                  setIsCreateQuestion(true);
                }}
              >
                Save Question
              </Button>
              <Button
                className={
                  "button-answer mx-3 mb-5 " +
                  (isCreateQuestion ? "d-none " : "d-inline-block ")
                }
                variant="danger"
                onClick={(e) => {
                  e.preventDefault();
                  setNewQuestion({
                    text: "",
                    type: "",
                    answer: [],
                    required: true,
                  });
                  setQuestionEdit({
                    text: "",
                    type: "",
                    answer: [],
                    required: true,
                  });
                  setAnswers([]);
                  setIsCreateQuestion(true);
                }}
              >
                Cancel
              </Button>

              <div className="btn-box-service">
                <Button
                  className={"ml-4 " + (isCreate ? "d-inline-block" : "d-none")}
                  variant="primary"
                  type="submit"
                >
                  Create a New Service
                </Button>
                <Button
                  className={"ml-4 " + (isCreate ? "d-none" : "d-inline-block")}
                  variant="primary"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    editService(editId);
                    setNewService({
                      name: "",
                      image: "",
                      description: "",
                      priceId: "",
                      category: "",
                      paymentType: "",
                    });
                    setNewQuestion({
                      text: "",
                      type: "",
                      answer: [],
                      required: true,
                    });
                    setQuestionEdit({
                      text: "",
                      type: "",
                      answer: [],
                      required: true,
                    });
                    setEditId(-1);
                    setIsCreate(true);

                    setIsCreateQuestion(true);
                    setQuestions([]);
                    setAnswers([]);
                  }}
                >
                  Save changes
                </Button>
                <Button
                  className={"ml-4 " + (isCreate ? "d-none" : "d-inline-block")}
                  variant="danger"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    setEditId(-1);
                    setNewService({
                      name: "",
                      image: "",
                      description: "",
                      priceId: "",
                      category: "",
                      paymentType: "",
                    });
                    setNewQuestion({
                      text: "",
                      type: "",
                      answer: [],
                      required: true,
                    });
                    setQuestionEdit({
                      text: "",
                      type: "",
                      answer: [],
                      required: true,
                    });
                    setIsCreate(true);
                    setQuestions([]);

                    setIsCreateQuestion(true);
                    setAnswers([]);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </Col>
          <Col className="col-3 p-0">
            <h4 className="form-group-slot head-questions-list">
              Questions-list
            </h4>
            {questions.map((ques) => {
              return (
                <div key={ques.text}>
                  <h4 className="question-list-item">{ques.text}</h4>
                  <Button
                    className="slotTiming-box-button"
                    variant="info"
                    onClick={(e) => {
                      console.log(ques);
                      e.preventDefault();
                      setIsCreateQuestion(false);
                      setNewQuestion(() => ques);
                      setAnswers(() => ques.answer);
                      setQuestionEdit(() => ques);
                    }}
                  >
                    /
                  </Button>
                  <Button
                    variant="danger"
                    className="slotTiming-box-button"
                    onClick={(e) => {
                      e.preventDefault();
                      setQuestions(
                        questions.filter(function (quesinarr) {
                          if (
                            ques.text === quesinarr.text &&
                            ques.type === quesinarr.type &&
                            ques.answer.sort() === quesinarr.answer.sort()
                          ) {
                            return false;
                          } else {
                            return true;
                          }
                        })
                      );
                    }}
                  >
                    X
                  </Button>
                </div>
              );
            })}
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          {serviceCards.map((serviceCard) => {
            return (
              <ServiceCard
                key={serviceCard.id}
                id={serviceCard.id}
                name={serviceCard.name}
                image={serviceCard.image}
                description={serviceCard.description}
                priceId={serviceCard.priceId}
                category={serviceCard.category}
                paymentType={serviceCard.paymentType}
                edit={edit}
              />
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default Service;
