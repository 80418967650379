// import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navigation/Navigation';
import { Route, Switch } from 'react-router-dom';

import Home from './pages';

import Service from './components/Service/Service';
import Slot from './components/Slot/Slot';

import Session from './components/Session/Session';

// import AddChildren from "./components/MyAccount/AddChild";

import Zoom from './components/Zoom/Zoom';

import NotFound from './components/NotFound/NotFound';

import ZoomRecording from './components/ZoomRecording/ZoomRecording';

//-----------google analytics----------
//const { GA_TRACKING_ID } = process.env;

/*
const trackEvent = (category, action, label, value) => {
  const data = {
    // API Version.
    v: "1",
    // Tracking ID / Property ID.
    tid: GA_TRACKING_ID,
    // Anonymous Client Identifier. Ideally, this should be a UUID that
    // is associated with particular user, device, or browser instance.
    cid: "555",
    // Event hit type.
    t: "event",
    // Event category.
    ec: category,
    // Event action.
    ea: action,
    // Event label.
    el: label,
    // Event value.
    ev: value,
  };

  return fetch("http://www.google-analytics.com/debug/collect", {
    params: data,
  });
};
*/
//-------------------------------------

function App() {
  return (
    <div className="App d-flex flex-column min-vh-100">
      {/* <span> */}
      <Route path="/" component={Navbar} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/service" component={Service} />
        <Route path="/session" component={Session} />
        <Route path="/slot" component={Slot} />
        <Route path="/zoom" component={Zoom} />
        <Route path="/recording" component={ZoomRecording} />

        <Route component={NotFound} />
      </Switch>
    </div>
  );
}

export default App;
