import React from 'react';
import { Nav, Navbar, NavDropdown, Image, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

export const NavLink = ({ title, href }) => (
  <Nav.Link className="navbar-item mx-3" href={href}>
    <b>{title}</b>
  </Nav.Link>
);

export const AboutDropdown = ({ setIsHovered, isHovered }) => (
  <NavDropdown
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    onToggle={
      isHovered
        ? () => {
            window.location.href = '/about';
          }
        : () => {}
    }
    show={isHovered}
    className="navbar-item"
    title={
      <span id="dropdown-title">
        <b>About Us</b>
      </span>
    }
    id="basic-nav-dropdown"
  >
    <NavDropdown.Item id="dropdown-item" href="/press">
      <b>Press</b>
    </NavDropdown.Item>
    <NavDropdown.Item id="dropdown-item" href="/faq">
      <b>FAQ</b>
    </NavDropdown.Item>
    <NavDropdown.Item id="dropdown-item" href="/blog">
      <b>Blog</b>
    </NavDropdown.Item>
    <NavDropdown.Item id="dropdown-item" href="/contact">
      <b>Contact Us</b>
    </NavDropdown.Item>
    <NavDropdown.Item id="dropdown-item" href="/career">
      <b>Careers</b>
    </NavDropdown.Item>
  </NavDropdown>
);

export const ZoomDropdown = ({ setIsZoomHovered, isZoomHovered }) => (
  <NavDropdown
    onMouseEnter={() => setIsZoomHovered(true)}
    onMouseLeave={() => setIsZoomHovered(false)}
    show={isZoomHovered}
    className="navbar-item mr-5"
    title={
      <span id="dropdown-title">
        <b>Zoom</b>
      </span>
    }
    id="basic-nav-dropdown"
  >
    <NavDropdown.Item id="dropdown-item" href="/zoom">
      <b>Bookings</b>
    </NavDropdown.Item>
    <NavDropdown.Item id="dropdown-item" href="/recording">
      <b>Recordings</b>
    </NavDropdown.Item>
  </NavDropdown>
);

export const SignInButton = ({ onClick }) => (
  <Navbar.Text className="mx-3 img-signin">
    <b
      style={{ cursor: 'pointer' }}
      // eslint-disable-next-line no-script-url
      href="javascript:;"
      id="navbar-item-signin"
      onClick={onClick}
    >
      <Image className="w-50 h-50 p-2 img-signin" src="/img/signin.svg" />
      Sign In
    </b>
  </Navbar.Text>
);

export const LogOutButton = withRouter(({ history }) => (
  <Button
    className="navbar-btn__book mr-5 ml-3 px-3 py-1 btn-square ml-auto"
    onClick={() => {
      localStorage.clear();
      let path = 'https://admin.techfunic.com';
      window.location.assign(path);
    }}
  >
    <span className="navbar-btn__book-text">
      <b>Logout</b>
    </span>
  </Button>
));
