import React from "react";
import { Card, Col, Button } from "react-bootstrap";
import "./SlotCard.css";

function SlotCard(props) {
  return (
    <div>
      <Col md={3}>
        <Card className="m-3" style={{ width: "18rem" }}>
          <Card.Body>
            <Card.Subtitle className="mb-2">
              <h4>{props.slotName}</h4>
              <h5>
                {props.startDate.split("T")[0]} -- {props.endDate.split("T")[0]}
              </h5>
            </Card.Subtitle>
            <Card.Text>Service ID -- {props.serviceID}</Card.Text>
            <Card.Text>Capacity -{props.capacity}</Card.Text>
            <Card.Text>Duration - {props.duration}</Card.Text>
            <Card.Text>Number Sessions - {props.numSessions}</Card.Text>
            <Card.Text>conference ID - {props.conferenceId}</Card.Text>
            <Button
              variant="primary"
              onClick={() => {
                props.edit(
                  props.id,
                  props.capacity,
                  props.slotName,
                  props.slotDescription,

                  props.startDate,
                  props.endDate,
                  props.duration,
                  props.userTimeZone,
                  props.numSessions,
                  props.conferenceId,
                  props.serviceID
                );
              }}
            >
              Edit
            </Button>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}

export default SlotCard;
