import React from 'react';
import '../components/css/Landing.css';

const Home = () => (
  <div
    style={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <h1>Welcome, Admin</h1>
  </div>
);

export default Home;
