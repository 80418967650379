import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import TimezoneSelect from "react-timezone-select";
import axios from "axios";
import Response from "./Response";
import SlotCard from "./SlotCard";

const Zoom = () => {
  const [slots, setSlots] = useState(null);
  const [search, setSearch] = useState("session");
  const [response, setResponse] = useState(null);
  
  const [topic, setTopic] = useState({
    service: '',
    slot: ''
  })

  const [loading, setLoading] = useState(false);
  const [meet, setMeet] = useState({
    time: "",
    date: "",
    duration: 60,
  });

  const [timezone, setTimezone] = useState({
    value: "America/New_York",
    label: "(GMT-4:00) Eastern Time",
    offset: -4,
    abbrev: "EDT",
    altName: "Eastern Daylight Time",
  });

  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value === "slot") {
      axios
        .get(process.env.REACT_APP_API_ENDPOINT + "/slots/")
        .then((res) => setSlots(res.data))
        .catch((err) => console.log(err));
    }
  };

  const handleChange = (e) => {
    setMeet({
      ...meet,
      [e.target.name]: e.target.value,
    });
  };

  const zoomSlotChecker = (input) => {
    setLoading(true);
    setResponse(null);
    axios
      .get(
        process.env.REACT_APP_ZOOMAPI_ENDPOINT,
        {
          params: input,
        }
      )
      .then((res) => {
        setLoading(false);
        setResponse(res.data);
        if(res.data.type === 'schedule'){
          res.data.urls.map(element => 
            axios.put(process.env.REACT_APP_API_ENDPOINT + "/sessions/url",{
              id: element.id,
              hostUrl: element.host_url,
              participantUrl: element.participant_url
            }).catch(err => console.log("Unable to update urls in sessions"))
          )
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    zoomSlotChecker({
      type: 1,
      input: [
      {
        id: 1,
        time: meet.time,
        duration: meet.duration,
        date: meet.date,
        timezone: timezone.value,
      },
    ]});
  };

  const timezones = {
    "America/Chicago": "Central Time",
    "America/Los_Angeles": "Pacific Time",
    "America/Boise": "Mountain Time",
    "America/New_York": "Eastern Time",
    "Asia/Kolkata": "Chennai, Kolkata, Mumbai, New Delhi",
  };

  return (
    <>
    <Row className="mt-7 mx-3">
      <Col className="mb-3">
        <Form onSubmit={(e) => handleSubmit(e)} className="text-right">
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Search by
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="select"
                name="searchby"
                onChange={handleSearch}
                required
              >
                <option value={"session"}>Session</option>
                <option value={"slot"}>Slot</option>
              </Form.Control>
            </Col>
          </Form.Group>
          {search === "session" && (
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Time
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    name="time"
                    type="time"
                    value={meet.time}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Date
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    name="date"
                    type="date"
                    value={meet.date}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="2">
                  Duration
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    as="select"
                    name="duration"
                    value={meet.duration}
                    onChange={handleChange}
                    required
                  >
                    <option value={30}>30</option>
                    <option value={45}>45</option>
                    <option value={60}>60</option>
                    <option value={75}>75</option>
                    <option value={90}>90</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="2">
                  TimeZone
                </Form.Label>
                <Col sm="8">
                  <TimezoneSelect
                    value={timezone}
                    onChange={setTimezone}
                    timezones={timezones}
                    required
                  />
                </Col>
              </Form.Group>
              <Row xs="6" className="justify-content-center">
              <Button type="submit" variant="dark">Check</Button>
              </Row>
            </>)}
        </Form>
        {search === 'slot' && 
        <SlotCard slots={slots} zoomSlotChecker={zoomSlotChecker} setTopic={setTopic}/>}
        <Response response={response} loading={loading} zoomSlotChecker={zoomSlotChecker} search={search}
        topic={topic}/>
      </Col>
    </Row>
    </>
  );
};

export default Zoom;
