import React, { useState } from "react";
import { Row, Card, Button, Col } from "react-bootstrap";
import axios from "axios";
import moment from "moment-timezone";

const SlotCard = ({ slots, zoomSlotChecker,setTopic }) => {
  const [slotinfo, setSlotinfo] = useState(null);
  const [sessioninfo, setSessioninfo] = useState(null);

  const handleSessionList = (slot) => {
    setSlotinfo(slot);
    setTopic({
      service: slot.ServiceId,
      slot: slot.slotName
    })
    axios
      .get(process.env.REACT_APP_API_ENDPOINT + "/sessions/", {
        params: {
          id: slot.id,
        },
      })
      .then((res) => {
        const sessions = res.data.filter((element) => element.SlotId === slot.id);
        setSessioninfo(sessions);
      })
      .catch((err) => console.log(err));
  };

  const handleSession = () => {
    const input = sessioninfo.map((element) => ({
      id: element.id,
      time: moment.tz(element.startTime, slotinfo.userTimeZone).format("HH:mm"),
      duration: slotinfo.duration,
      date: moment
        .tz(element.startTime, slotinfo.userTimeZone)
        .format("YYYY-MM-DD"),
      timezone: slotinfo.userTimeZone || "America/New_York",
    }));
    zoomSlotChecker({type: 1,input: input});
  };

  return (
    <>
      <Row>
        {slots &&
          slots.map((slot) => (
            <Card key={slot.id} className="m-1">
              <Card.Body>
                <div>ID : {slot.id}</div>
                <div>Name : {slot.slotName}</div>
                <div>Start Date: {slot.startDate.split("T")[0]}</div>
                <div>End Date : {slot.endDate.split("T")[0]}</div>
                <div>TimeZone : {slot.userTimeZone || "America/New_York"}</div>
                <div>num of sessions : {slot.numSessions}</div>
                <Button
                  variant="secondary"
                  className="mt-3"
                  onClick={() => handleSessionList(slot)}
                >
                  See Sessions
                </Button>
              </Card.Body>
            </Card>
          ))}
      </Row>
      <Row>
        <Col className="text-left">
          {sessioninfo && (
            <Col className="py-3">
              {sessioninfo.map((element,index) => (
                <li key={index}>{`${moment
                  .tz(element.startTime, slotinfo.userTimeZone)
                  .format("ddd, Do MMM YYYY, HH:mm A")} -- 
           ${moment
             .tz(element.endTime, slotinfo.userTimeZone)
             .format("HH:mm A")} `}</li>
              ))}
              <Button variant="dark" onClick={() => handleSession()} className="my-3">
                Check All
              </Button>
            </Col>
          )}
        </Col>
      </Row>
    </>
  );
};
export default SlotCard;
