/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { ZoomDropdown, NavLink } from './NavComponents';

import '../css/Navigation.css';

function Navigation(props) {
  // eslint-disable-next-line no-unused-vars
  const [nav, setNav] = useState('');
  // eslint-disable-next-line no-unused-vars
  let [Token, setToken] = useState('');
  const { location } = props;

  const [isZoomHovered, setIsZoomHovered] = useState(false);

  return (
    <>
      <header>
        <Navbar fixed="top" className="navbar" variant="light" expand="xl">
          <Navbar.Brand className="mx-5" href="https://admin.techfunic.com">
            <img
              alt=""
              src="/img/techfunic-logo.webp"
              width="150"
              height="40"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav ">
            <Nav activeKey={location.pathname} className="ml-auto my-2 mr-2">
              <NavLink title="Service" href="/service" />
              <NavLink title="Batch" href="/slot" />
              <NavLink title="Session" href="/session" />
              <ZoomDropdown
                setIsZoomHovered={setIsZoomHovered}
                isZoomHovered={isZoomHovered}
              />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </>
  );
}

export default withRouter(Navigation);
