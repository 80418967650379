import React, { useState } from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import TimezoneSelect from 'react-timezone-select';
import ReactTable from './ReactTable';

const ZoomRecording = () => {
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(null);
  const [f, setF] = useState(false);
  const [meet, setMeet] = useState({
    time: '',
    startdate: '',
    enddate: '',
  });
  const [timezone, setTimezone] = useState({
    value: 'America/New_York',
    label: '(GMT-4:00) Eastern Time',
    offset: -4,
    abbrev: 'EDT',
    altName: 'Eastern Daylight Time',
  });

  const timezones = {
    'America/Chicago': 'Central Time',
    'America/Los_Angeles': 'Pacific Time',
    'America/Boise': 'Mountain Time',
    'America/New_York': 'Eastern Time',
    'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  };

  const zoomVideoRecording = (data) => {
    // const input = data.input;
    data.startdate = data.startdate + 'T00:00:00';
    data.enddate = data.enddate + 'T24:00:00';
    if (meet) {
      data.startdate = moment.tz(data.startdate, timezone.value).format();
      data.enddate = moment.tz(data.enddate, timezone.value).format();
    }
    // console.log(data.startdate, data.enddate);
    setLoading(true);
    setRecording(null);
    axios
      .get(process.env.REACT_APP_API_ENDPOINT + '/zoom_recording/', {
        params: {
          startdate: data.startdate,
          enddate: data.enddate,
        },
      })
      .then((res) => {
        setLoading(false);
        setRecording(res.data);
      })
      .catch((err) => console.log(err));
  };

  if (!recording && f === false) {
    let data = {
      startdate: moment().format('YYYY-MM-DD'),
      enddate: moment().format('YYYY-MM-DD'),
    };
    zoomVideoRecording(data);
    setF(true);
  }

  const handleChange = (e) => {
    setMeet({
      ...meet,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(meet.startdate, meet.enddate);

    zoomVideoRecording({
      startdate: meet.startdate,
      enddate: meet.enddate,
    });
  };

  return (
    <>
      <Row className=" pt-5 mt-5 pt-2 ml-5">
        <Form
          onSubmit={(e) => handleSubmit(e)}
          className="text-left mt-4 pl-5 ml-5 "
        >
          <Row className=" ml-5" style={{ width: '70rem' }}>
            <Col md={3}>
              <Form.Group className="mb-3">
                <Form.Label className="">Start Date</Form.Label>
                <Form.Control
                  name="startdate"
                  type="date"
                  value={meet.startdate}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3 ">
                <Form.Label className="">End Date</Form.Label>
                <Form.Control
                  name="enddate"
                  type="date"
                  value={meet.enddate}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mb-3 ">
                <Form.Label className="">TimeZone</Form.Label>
                <TimezoneSelect
                  value={timezone}
                  onChange={setTimezone}
                  timezones={timezones}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={3}>
              <Form.Group className="mt-4 mx-3 p-2 px-2">
                <Button type="submit" variant="dark">
                  Filter
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Row>
      <div className="m-5 pl-5 ">
        <Row className="pl-5">
          <Row className="p-3 ">
            {loading && (
              <Spinner
                animation="border"
                role="status"
                style={{ marginLeft: '38rem' }}
              >
                <span className="sr-only ml-5">Loading...</span>
              </Spinner>
            )}
            {recording && recording.length === 0 && (
              <h5 style={{ marginLeft: '32rem' }}>No Zoom Video Recordings</h5>
            )}
            {recording && recording.length !== 0 && (
              <ReactTable
                recording={recording}
                timezone={timezone}
                meet={meet}
              />
            )}
          </Row>
        </Row>
      </div>
    </>
  );
};

export default ZoomRecording;
