import React from "react";
import { Card, Button, Col } from "react-bootstrap";
import "./ServiceCard.css";

function ServiceCard(props) {
  return (
    <div>
      <Col md={3}>
        <Card className="m-3" style={{ width: "18rem" }}>
          <Card.Body>
            <Card.Title>
              <b>{props.id}</b>. {props.name}
            </Card.Title>
            <Card.Img variant="top" src={props.image} />
            <Card.Subtitle className="mb-2 text-muted">
              {props.description}
            </Card.Subtitle>
            <Card.Text>{props.category}</Card.Text>
            <Card.Text>{props.paymentType}</Card.Text>
            <Card.Text>Price ID : {props.priceId}</Card.Text>
            <Button
              variant="primary"
              onClick={() => {
                props.edit(
                  props.id,
                  props.name,
                  props.image,
                  props.description,
                  props.priceId,
                  props.paymentType,
                  props.category
                );
              }}
            >
              Edit
            </Button>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}

export default ServiceCard;
