import moment from "moment-timezone";


const newVideo = (video,timezone,meet) => {
  let duration=moment(video.end_time).diff(moment(video.start_time));
  if(meet)
  {
    // console.log(video.end_time,video.start_time)
    return {
      date: moment.tz(video.date, timezone.value).format("DD-MM-YYYY"),
      category: video.folder,
      batch: video.subfolder,
      videolink:<a href={video.url} target="_blank" rel="noreferrer" >link </a>,
      meetingid: video.meetingid,
      starttime: moment.tz(video.start_time, timezone.value).format("hh:mm A"),
      endtime:moment.tz(video.end_time, timezone.value).format("hh:mm A"),
      duration: Math.ceil(duration/(1000*60)),
      url: video.url,
      
    }
  }
  else{
    return {
      date: moment(video.date).format("DD-MM-YYYY"),
      category: video.folder,
      batch: video.subfolder,
      videolink:<a href={video.url} target="_blank" rel="noreferrer" >link </a>,
      meetingid: video.meetingid,
      starttime: moment(video.start_time).format("hh:mm A"),
      endtime: moment(video.end_time).format("hh:mm A"),
      duration: Math.ceil(duration/(1000*60)),
      url: video.url,
      
    }
  }
}

export default function makeData(data,timezone,meet) {
  const makeDataLevel = (depth = 0) => {
    const lens=data.length;
    return data.map(video => {
      return {
        ...newVideo(video,timezone,meet),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      }
    })
  }

  return makeDataLevel()
}
