import React from 'react';
import { Input } from 'reactstrap';

export const Filter = ({ column }) => {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render('Filter')}
    </div>
  );
};
export const filterLessThan = (rows, id, filterValue) => {
  if (filterValue) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue <= filterValue;
    });
  } else {
    return rows;
  }
};
export const SliderColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the min and max
  // using the preFilteredRows

  React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <Input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(parseInt(e.target.value || undefined, 10));
        }}
        placeholder={`Search `}
      />
      {/* <input
        // type="range"
        value={filterValue || max}
        onChange={e => {
          setFilter(parseInt(e.target.value, 10))
        }}
      /> */}
    </>
  );
};

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterLessThan.autoRemove = (val) => typeof val !== 'number';

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
  return (
    <Input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search `}
    />
  );
};
