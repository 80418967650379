import React from 'react';
import { Table } from "react-bootstrap";
import moment from "moment-timezone";

const Conflict = ({conflict}) => {
    const sortByDate = (meet1, meet2) => {
        if (meet1.start_time > meet2.start_time) return 1;
        else if (meet1.start_time < meet2.start_time) return -1;
        return 0;
      };
    
    return(
        <div className="mt-2">
            <h5>
              <b>Conflict</b>
            </h5>
            <Table>
              <thead>
                <tr>
                  <th className="px-1">Account</th>
                  <th className="px-1">Topic</th>
                  <th className="px-1">Date</th>
                  <th className="px-1">Time</th>
                  <th className="px-1">Dur.</th>
                </tr>
              </thead>
              <tbody>
                {conflict.sort(sortByDate).map((meet, index) => (
                  <tr key={index}>
                    <td className="px-1">{meet.account}</td>
                    <td className="px-1">{meet.topic}</td>
                    <td className="px-1">
                      {moment(meet.start_time)
                        .tz(meet.timezone)
                        .format("DD MMM YYYY")}
                    </td>
                    <td className="px-1">
                      {moment(meet.start_time)
                        .tz(meet.timezone)
                        .format("HH : mm")}
                    </td>
                    <td className="px-1">{meet.duration}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
    )
}

export default Conflict;